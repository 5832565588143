<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-navigation-drawer fixed floating mini-variant color="rgb(0, 0, 0, 0)" :light="current.dark ? false : true">
      <v-list dense flat class="d-flex flex-column justify-center fill-height">
        <v-list-item-group v-model="current" mandatory>
          <v-list-item v-for="item in items" :key="item.title" style="flex: none" :value="item" @click="$vuetify.goTo(`#${item.id}`)">
            <template v-slot:default="{ active }">
              <v-tooltip right color="grey darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <v-icon v-if="active">mdi-circle</v-icon>
                    <v-icon v-else>mdi-circle-outline</v-icon>
                  </v-list-item-icon>
                </template>
                <span>{{ item.label }}</span>
              </v-tooltip>
              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar flat color="rgb(0, 0, 0, 0)">
      <router-link to="/"><v-img eager contain height="48" src="/images/logo_white.svg"></v-img></router-link>
      <v-spacer></v-spacer>
      <v-btn light href="https://sky.alphaxds.com" target="_blank">Introducing AlphaX Sky!</v-btn>
      <v-spacer></v-spacer>
      <v-btn fab depressed color="white" small href="https://www.linkedin.com/company/alphaxds" target="_blank"><v-icon color="#0072b1">mdi-linkedin</v-icon></v-btn>
    </v-app-bar>
    <v-main>
      <home id="home" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
      <work id="work" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
      <software id="software" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
      <deploy id="deploy" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
      <execute id="execute" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
      <knowledge id="knowledge" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
      <about id="about" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
      <contact id="contact" v-observe-visibility="{ callback: visibilityChanged, intersection: { threshold: 0.5 }}" />
    </v-main>
  </v-app>
</template>

<script>
import Home from '@/views/Home'
import Work from '@/views/Work'
import Software from '@/views/Software';
import Deploy from '@/views/Deploy.vue';
import Execute from '@/views/Execute.vue';
import About from '@/views/About.vue';
import Contact from '@/views/Contact.vue';
import Knowledge from '@/views/Knowledge.vue';
export default {
  name: "app",
  components: { Home, Work, Software, Deploy, Execute, About, Contact, Knowledge },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  data() {
    return {
      current: 'home',
      tooltip: false,
      items: [
        { label: 'Home', id: 'home', dark: true },
        { label: 'Work', id: 'work', dark: false },
        { label: 'Software', id: 'software', dark: true },
        { label: 'Deploy', id: 'deploy', dark: false },
        { label: 'Execute', id: 'execute', dark: true },
        { label: 'Knowledge', id: 'knowledge', dark: false },
        { label: 'About', id: 'about', dark: true },
        { label: 'Contact', id: 'contact', dark: true },
      ] 
    }
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        this.current = this.items.find(e => e.id == entry.target.id)
      }
    }
  }
};
</script>
