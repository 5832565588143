<template>
  <section class="d-flex flex-column flex-grow-1 justify-center pa-4 pa-sm-8 pa-lg-16 grey lighten-2" :class="xHeight">
    
    <header class="pa-sm-8 pa-lg-16 grey--text text--darken-4">
      <p class="text-h6 text-md-h5 text-lg-h4 text-uppercase">Selected keynotes and publications</p>
      <h1 class="text-h3 text-md-h2 text-lg-h1 font-weight-bold">From around the world</h1>
    </header>


    <p class="pt-8 pt-sm-0 px-4 px-sm-8 px-lg-16 text-center">
      <v-btn icon light @click="prev"><v-icon large>mdi-chevron-left</v-icon></v-btn>
      <v-btn icon light @click="next"><v-icon large>mdi-chevron-right</v-icon></v-btn>
    </p>

    <v-window v-model="slider" show-arrows class="pt-0 px-sm-8 px-lg-16">
      <template v-for="(c, i) in chunk(items, 6)">
        <v-window-item :key="`w${i}`">
          <v-row>
            <template v-for="(item, j) in c">
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" :key="`a${j}`">
                <v-card light tile class="fill-height" :href="item.href" target="_blank">
                  <v-img :aspect-ratio="16/9" eager :src="`/images/${item.image}`"></v-img>
                  <v-card-title v-html="item.title"></v-card-title>
                  <v-card-subtitle class="caption font-weight-bold">{{ item.date }}</v-card-subtitle>
                  <v-card-text>{{ item.text }}</v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-window-item>
      </template>
    </v-window>

  </section>
</template>

<script>
export default {
  name: "Knowledge",
  data() {
    return {
      show: false,
      slider: 0,
      items: [
        {
          image: 'etvf.png',
          date: 'September 2022',
          title: 'AlphaX participating in ETVF 2022',
          text: 'Energy Tech Venture Forum, hosted by The Rice Alliance for Technology and Entrepreneurship, is the premier energy tech venture capital conference to connect energy innovators, investors, corporates, and the energy ecosystem.',
          href: 'https://alliance.rice.edu/sites/alliance/files/2022-09/Company_Directory_2022_ETVF.pdf'
        },
        {
          image: 'hart_energy.jpg',
          date: 'July 2022',
          title: 'HART Energy features AlphaX Software',
          text: 'AI-driven software for unconventional assets helps operators finetune completion strategies and democratizes access to accurate production forecasting.',
          href: 'https://www.dropbox.com/s/9mj3y7esqbyhvwn/AlphaXHartArticle.pdf'
        },
        {
          image: 'ai_future.jpg',
          date: 'April 2022',
          title: 'A.I. & The Future of Houston Conference',
          text: 'The AI and the Future of Houston Conference aims to explore the technological advances and innovation brought by AI.',
          href: 'https://www.eventbrite.com/e/ai-the-future-of-houston-conference-registration-308450562937'
        },
        {
          image: 'spe.png',
          date: 'July 2021',
          title: 'Data Science Convention',
          text: 'Fulfilling the Potential of the Digital Transformation to Navigate the Energy Transition - by Dr. Ram Shenoy',
          href: 'https://www.spegcs.org/events/6018/'
        },
        {
          image: 'substack.webp',
          date: 'May 2021',
          title: 'Future of Energy (Podcast)',
          text: 'How to build a successful business seeing the energy transition as an opportunity',
          href: 'https://mikatienhaara.substack.com/p/s1e10-future-of-energy-how-to-build'
        },
        {
          image: 'bend.jpg',
          date: "May 2021",
          title: "Applications of AI",
          text: "The Fort Bend Future Initiative's Artificial Intelligence Series, “Applications of AI?” How experts use AI in their businesses.",
          href: "https://m.facebook.com/events/462958834978635",
        },
        {
          image: 'otc.jpg',
          date: "Nov 2020",
          title: "OTC Live Panel",
          text: "\"AI Accelerated Creative Destruction: O&G's Pivotal 20's\" talk on Panel, OTC Live - Innovation in Upstream Oil and Gas: From Development to Scale-Up Implementation",
          href: "https://www.youtube.com/watch?v=5MhMoGXT8Ic",
        },
        {
          image: 'spe.png',
          date: "Jun 2020",
          title: "SPE AAPG Webinar",
          text: "Reinvention in Times of Disruption",
          href: "https://www.spegcs.org/events/5699/",
        },
        {
          image: 'otc.jpg',
          date: "May 2020",
          title: "OTC Paper",
          text: "Acceleration of 4IR Driven Digital Transformation Through Open Source: Methods and Parallel Industries Knowledge Reapplication in the Field",
          href: "https://onepetro.org/OTCONF/proceedings-abstract/20OTC/4-20OTC/D041S055R004/107475",
        },
        {
          image: 'chemindix.png',
          date: "Jun 2019",
          title: "Conference and Exhibition on Chemistry in Industry (ChemIndix)",
          text: "Role of Artificial Intelligence in the World of Molecules",
          href: "https://chemindix.org/panelist-and-keynote-speakers/",
        },
        {
          image: 'spe.png',
          date: "Apr 2019",
          title: "SPE Gulf Coast Section",
          text: "You Want the Truth? Adapting O&G Organizations to Make Digital Transformation Successful",
          href: "https://www.spegcs.org/events/4077/",
        },
        {
          image: 'meos.png',
          date: "Mar 2019",
          title: "Middle East Oil Conference (MEOS) 2019",
          text: '"Accelerating 4IR in the O&G Sector" Presentation and Panel "4th Industrial Revolution (IR 4.0)"',
          href: "http://meos-expo.com/site/wp-content/uploads/MEOS-2019-conference-preview.pdf",
        },
        {
          image: 'ceraweek.png',
          date: "Mar 2019",
          title: "CERA Week 2019",
          text: 'Agora Presentation, "AI Comes of Age" Sammy Haroon, Founder and CEO, AlphaX Decision Sciences speaks about how AI enables businesses to make faster, smarter decisions with pragmatic processes, sharing their experiences and what they have learned from customers about what works and doesn\'t work',
          href: "https://ondemand.ceraweek.com/detail/video/6020601387001/ai-comes-of-age",
        },
        {
          image: 'adipec.png',
          date: "Nov 2018",
          title: "Abu Dhabi Int’l Petroleum Exhibition Conference (ADIPEC) 2018",
          text: "Big Data-Driven Advanced Analytics: Application of Convolutional and Deep Neural Networks for GPU Based Seismic Interpretations",
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/2-18ADIP/D021S035R003/213071",
        },
        {
          image: 'adipec.png',
          date: "Nov 2018",
          title: "Abu Dhabi Int’l Petroleum Exhibition Conference (ADIPEC) 2018",
          text: "From Insight to Foresight: Knowing how to Apply Artificial Intelligence in the Oil and Gas Industry",
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/3-18ADIP/D031S066R003/212948",
        },
        {
          image: 'usrcc.jpeg',
          date: "Nov 2018",
          title: 'USRCC "3rd Technology and Innovation Conference" Houston',
          text: "From Insight to Foresight: Knowing how to Apply Artificial Intelligence in the Oil and Gas Industry",
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/3-18ADIP/D031S066R003/212948",
        },
        {
          image: 'aramco.png',
          date: "Sep 2018",
          title: "iQuest Presentation to SAO EXPEC ARC",
          text: "Application of Deep Neural Networks for GPU Based Seismic Interpretations",
          href: "https://www.aramco.com/-/media/publications/dimensions/2018-dimi-winter.pdf",
        },
        {
          image: 'aramco.png',
          date: "Sep 2018",
          title: "iQuest Presentation to SAO EXPEC ARC",
          text: "4IR Based Data Residency and Privacy Solutions, and Un-hackable Analytics Cloud and Technology Infrastructure",
          href: "https://www.aramco.com/-/media/publications/dimensions/2018-dimi-winter.pdf",
        },
        {
          image: 'aapg.png',
          date: "May 2018",
          title: "AAPG Presentation",
          text: 'Abstract "From Insight to Foresight"',
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/3-18ADIP/D031S066R003/212948",
        },
        {
          image: 'rice.png',
          date: "May 2018",
          title: "Rice Alliance OTC Event",
          text: "Won Most Promising Company from Elevator Pitch",
          href: "https://jpt.spe.org/university-group-lists-most-promising-oil-gas-startups-year",
        },
      ],
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    chunk(array) {
      const chunk_size = this.getChunk()
      return Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size))
    },
    getChunk() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 1
        case 'sm': return 3
        case 'md': return 4
        case 'lg': return 4
        case 'xl': return 6
        default: return 6
      }
    },
    next() {
      this.slider = this.slider + 1 === this.items.length ? 0 : this.slider + 1;
    },
    prev() {
      this.slider = this.slider - 1 < 0 ? this.items.length - 1 : this.slider - 1;
    },
  }
};
</script>