<template>
  <section style="background-image: url('/images/about.jpg'); background-size: cover;" class="d-flex flex-column flex-grow-1 justify-center pa-4 pa-sm-8 pa-md-12 pa-lg-16" :class="xHeight">
    <header class=" pa-sm-8 pa-md-12 pa-lg-16">
      <p class="text-h6 text-md-h5 text-lg-h4 text-uppercase">Who We Are</p>
      <h1 class="text-h3 text-md-h2 text-lg-h1 font-weight-bold">Entrepreneurial &amp; Executive Experience</h1>
    </header>

    <v-row class="pt-8 px-8 px-sm-8 px-md-12 px-lg-16">
      <v-col v-for="person in team" :key="person.id" cols="12" sm="6" md="4" lg="3" xl="2">
        <v-dialog width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-card tile :light="$vuetify.theme.dark" :dark="!$vuetify.theme.dark" v-bind="attrs" v-on="on">
              <template v-if="$vuetify.breakpoint.smAndDown">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-avatar class="ma3" size="110" tile>
                    <v-img eager :src="`/images/${person.photo}.jpg`"></v-img>
                  </v-avatar>
                  <div class="flex-grow-1">
                    <v-card-title v-text="person.name"></v-card-title>
                    <v-card-subtitle>{{ person.title }} <br> {{ person.location }}</v-card-subtitle>
                  </div>
                </div>
              </template>
              <template v-else>
                <v-img eager :src="`/images/${person.photo}.jpg`"></v-img>
                <v-card-title v-text="person.name"></v-card-title>
                <v-card-subtitle>{{ person.title }} - {{ person.location }}</v-card-subtitle>
              </template>
            </v-card>
          </template>
          <v-card :light="$vuetify.theme.dark" :dark="!$vuetify.theme.dark">
            <v-card-title class="headline">{{ person.name }}</v-card-title>
            <v-card-text>
              <p v-for="(row, i) in person.bio" :key="`r${i}`">{{ row }}</p>  
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="pb-8 px-8 px-sm-8 px-md-12 px-lg-16">
      <v-col v-for="person in advisors" :key="person.id" cols="12" sm="6" md="4" lg="3" xl="2">
        <v-dialog width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-card tile :light="$vuetify.theme.dark" :dark="!$vuetify.theme.dark" v-bind="attrs" v-on="on">
              <template v-if="$vuetify.breakpoint.smAndDown">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-avatar class="ma3" size="110" tile>
                    <v-img eager :src="`/images/${person.photo}.jpg`"></v-img>
                  </v-avatar>
                  <div class="flex-grow-1">
                    <v-card-title v-text="person.name"></v-card-title>
                    <v-card-subtitle>{{ person.title }} <br> {{ person.location }}</v-card-subtitle>
                  </div>
                </div>
              </template>
              <template v-else>
                <v-img eager :src="`/images/${person.photo}.jpg`"></v-img>
                <v-card-title v-text="person.name"></v-card-title>
                <v-card-subtitle>{{ person.title }} - {{ person.location }}</v-card-subtitle>
              </template>
            </v-card>
          </template>
          <v-card :light="$vuetify.theme.dark" :dark="!$vuetify.theme.dark">
            <v-card-title class="headline">{{ person.name }}</v-card-title>
            <v-card-text>
              <p v-for="(row, i) in person.bio" :key="`r${i}`">{{ row }}</p>  
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

  </section>
</template>
<script>
export default {
  name: 'Team',
  data() {
    return {
      show: false,
      team: [
        { id: 'sammy', name: 'Sammy Haroon', title: 'Founder & CEO', location: 'San Jose, CA', photo: 'sammy', 
        bio: [
          `Mr. Haroon is a startup entrepreneur and corporate executive with over 25 years of experience in creating and monetizing opportunities in advanced information technology, software, data analytics, design, and science by delivering products and services across various sectors, including Oil & Gas, E-Commerce, CPG, ERP, Banking, Finance, SmartGrid, and Electronics.`,
          `He has held a variety of executive leadership and technology consulting positions with Fortune 100 companies, including Procter & Gamble, GE, and Delta Airlines. Most recently at Baker Hughes, he was the Director of the Palo Alto Innovation Center and the Director of Enterprise Data Analytics.`,
          `He is currently an advisor Silicon Valley Alchemist Accelerator and to the CEO of Riphah International University and RAK College of Dental Sciences. He is the Chairman of the Committee on Technology and Innovation at Bilateral US-Arab Chamber. Previously, he was on the Board of Advisors of Saffron Technology, a cognitive analytics company bought by Intel. Mr. Haroon has a Bachelor's Degree in Nuclear Engineering from Georgia Institute of Technology. He is based out of San Jose, California.`
        ]},
        { id: 'aruna', name: 'Aruna Viswanathan', title: 'Co-Founder, COO', location: 'Houston, TX', photo: 'aruna', 
        bio: [
          `Ms. Viswanathan brings over 20 years of experience in various roles including as an engineer, venture fund advisor, technology accelerator executive, private equity investor, business owner, and family office CIO/CFO. She was most recently Chief Underwriting Officer and Co-Founder for Clearspring Capital Group with multiple exits to Fortune 500 companies.`,
          `Previously, Ms. Viswanathan served a five-year term on the Texas Emerging Technology Fund Committee, was a past Director of the Houston Angel Network and was the Vice-President and Director of Operations for the Houston Technology Center. Prior to her work in operations and finance, Ms. Viswanathan worked in a variety of engineering roles at AMD and Motorola's Wireless Signal Processing Division. She currently serves on the board of directors of Industial Tech (NASDAQ: ITAC) and on the board of the Houston Chapter of TiE, a global entrepreneurship organization.`,
          `Ms. Viswanathan holds Bachelors and Masters degrees in Electrical Engineering from The University of Texas at Austin and an MBA from Rice University in Houston. She is based out of Houston, Texas.`
        ]},
        { id: 'ram', name: 'Ram Shenoy', title: 'Director', location: 'Houston, TX', photo: 'ram', 
        bio: [
          `Dr. Shenoy is CEO of the RBR Group, a technology product and service commercialization company working with Fortune Global 500 clients. He is also Technical Director and Chair of the Technical Committee of the Project Production Institute, whose mission is to radically improve the execution of large capital projects through the application of operations science.`,
          `Dr. Shenoy spent 20 years with Schlumberger, holding a variety of roles in research, development, commercialization and operations support, across the world. His last position with Schlumberger was as Vice-President of Research, leading Schlumberger’s global corporate research laboratories. He subsequently became Chief Technology Officer of ConocoPhillips, a position he held for 4 years. He currently advises venture capital and private equity companies on matters of technology and business strategy in the energy sector. From 2013-2017, he served on the U. S. Secretary of Energy Advisory Board, as the sole representative from the oil & gas industry.`,
          `He has a B.A, and M. A. from the University of Cambridge UK, a Ph.D. in Electrical Engineering from Cornell University NY, and an MBA from the Stern School of Business, New York University. He is based out of Houston, Texas.`
        ]},
      ],
      advisors: [
        { id: 'sergey', name: 'Sergey Alyamkin', title: 'Advisor', location: 'Novosibirsk, RU', photo: 'sergey', 
        bio: [
          `Dr. Sergey Alyamkin is an advisor and previous Chief Data Scientist of AlphaX Decision Sciences. He is currently the CTO at Expasoft, an AI consultant company, and co-founder of ENOT.ai, which develops technology for neural network compression for edge devices. Previously, Sergey was the Lead Data Scientist at Baker Hughes where he was instrumental in leading the team and developing sub-surface oil and gas solutions for chemicals, drilling and artificial lift.`,
          `Sergey is the author of 25+ scientific publications, related to data science and machine learning applications. He is the author of a patent for a new type of seismic sensor; Sergey and his team developed a set of novel algorithms for seismic sensors that allow for processing of multichannel seismic data in real-time on low-power DSP.`,
          `Sergey earned his Ph.D. from Novosibirsk State University in the field of Computer Science. The focus of his Ph.D. thesis was related to advanced methods for machine learning in seismic signal processing.`
        ]},
        { id: 'tony', name: 'Anthony A. Cucolo III', title: 'Advisor', location: 'Austin, TX', photo: 'tony', 
        bio: [
          `Tony Cucolo is the President and CEO of Professional Contracting Services, Incorporated, a nation-wide non-profit that enhances the lives of people with disabilities through the dignity and empowerment of employment. He was selected as CEO in April of 2022.`,
          `After graduating from the US Military Academy, he served more than 35 years in the U.S. Army, retiring at the rank of Major General in September 2014. His uniformed service took him worldwide, leading organizations varying in size from 100 personnel to 22,000 in peace and war.`,
          `Prior to leading PCSI, Tony spent more than four years on the executive team of the University of Texas System Administration. His duties there included building the System's strategic plan, conducting leader development programs for students, faculty, and administrators, and providing support for all veteran students and employees across the System's eight universities and six medical institutions.`,
          `He then helped establish and run the National Security Innovation Council, an Austin-based non-profit organization that connects businesses and entrepreneurs from across Texas to help solve national security problems. He remains their senior military advisor.`,
          `Tony still returns to the Army several times a year as a senior mentor for leadership programs and is on the faculty of Thayer Leadership, one of the nation's premier executive leadership development organizations. Additionally, he is a member of the Board of Directors for Texas 2036, and he serves on the board of the UT MD Anderson Cancer Center Leadership Institute.`
        ]},
        { id: 'john', name: 'John Howell', title: 'Advisor', location: 'Chapel Hill, NC', photo: 'john', 
        bio: [
          `John has more than 40 years of experience in the oil and gas industry in management, consulting and technical roles. John started Portfolio Decisions in 1998 to meet the increasing demand of companies for assistance with planning, strategy and portfolio management issues. John was the CEO and President of Portfolio Decisions, LLC until 2015 when Portfolio Decisions merged with 3esi.`,
          `Prior to starting Portfolio Decisions, John worked for Shell Oil serving in a variety of technical and managerial roles in the US and international sector. John is a graduate of Stanford University, where he received a BS and MS in Geophysics, with highest distinction, and is a member of AAPG, SEG and SPE. He is currently serving as an advisor to Aucerna and AlphaX Decision Sciences and is a Professor of the Practice at Kenan-Flagler Business School at the University of North Carolina.`
        ]},
        { id: 'dale', name: 'Dale McMullen', title: 'Industry Advisor', location: 'Dallas, TX', photo: 'dale', 
        bio: [
          `Dale is a seasoned technology executive and innovator with over 30 years of experience in software engineering, product management and enterprise operations.  His industry experience spans both the upstream oil & gas (drilling, completions, production) and energy services (power-generation, distribution) sectors. `,
          `Dale previously ran product management and software development as Global Head of Research & Development for a drilling, production, and reservoir modeling software solutions company. Prior to that, he held executive leadership positions at a commercial planning and enterprise operations software provider, as well as within the digital software division of a leading oilfield services company. In addition to technology leadership, Dale was a regional training coordinator for Directional Drilling operations, and also brings "hands-on" field expertise in completions and production facilities.`,
          `Dale holds a Ph.D. in Computer Science from Colorado School of Mines, a Masters in Computer Information Systems, and a Bachelor of Science in Engineering from Colorado School of Mines`
        ]},
        { id: 'ben', name: 'Ben Zapp', title: 'Industry Advisor', location: 'Denver, CO', photo: 'ben', 
        bio: [
          `Ben Zapp brings over 13 years of experience as an engineer spanning roles in multiple shale basins including the DJ, Bakken, Powder River, and most recently the Permian Basin. He has extensive experience in oil and gas field operations, as well as technology and workflow development within complex organizations, both small and large.`,
          `Currently, Mr. Zapp is the completions and production Manager at Lario Oil & Gas, a family-owned oil and gas company with over 90 years of operational history. Mr. Zapp has designed workflows and in-house technology solutions to scale the company’s Permian Basin asset to over 100 horizontal wells.`,
          `Mr. Zapp holds a Bachelors degree in Mechanical Engineering from the Colorado School of Mines and an MBA from the University of Denver – Daniels College of Business. Mr. Zapp is also a registered Professional Engineer in the states of Colorado and Texas.`
        ]},
      ]
    }
  }
}
</script>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>