<template>
  <section style="background-image: url('/images/software.jpg'); background-size: cover;" class="d-flex flex-column flex-grow-1 justify-center" :class="xHeight">
    <v-row align="stretch" no-gutters>
      <v-col md="6" class="text-sm-end align-self-center">
        <header class="pa-4 pa-sm-12 pa-lg-16">
          <p class="text-h6 text-md-h5 text-lg-h4 text-uppercase">AX-AI Software</p>
          <h1 class="text-h3 text-md-h2 text-lg-h1 font-weight-bold">Data driven mathematical solutions</h1>
          <p class="text-h6 text-md-h5 text-lg-h4">For oil and gas subsurface</p>
        </header>
        <v-list color="rgb(0, 0, 0, 0)">
          <v-list-item-group v-model="current" mandatory>
            <v-list-item v-for="item in products" :key="item.id" :value="item.id">
              <v-list-item-content>
                <v-list-item-title class="text-h6 text-uppercase font-weight-bold">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col md="6">
        <v-card light class="pa-sm-8 pa-lg-16 fill-height d-flex flex-column justify-center" flat tile>
          <template v-if="current == 'sky'">
            <blockquote class="blockquote text-right"><v-icon x-large>mdi-format-quote-open</v-icon><strong>AI comes of Age</strong> - <a href="https://ceraweek.com/program/sessiondetail.html?sid=799&tsid=800560">CERA Week, 2019</a></blockquote>
            <v-card-title class="text-h2 mb-4">Production Forecasting</v-card-title>
            <v-card-subtitle class="text-subtitle-1 font-weight-bold">Fast, predictable, and repeatable AI-based EUR forecasts through spatial, temporal and induced phenomena understanding</v-card-subtitle>
            <v-card-text class="text-subtitle-1">The industry's first open source based AX-AI driven software providing repeatable and reproduceable forecasts and powerful performance evaluation tools for reservoir and production engineers through a gamified user interface and experience. Software addresses common engineering workflows, including quick economic calculations, type curves and P-value distributions.</v-card-text>
            <v-card-text class="text-subtitle-1 pt-0">Uses <strong>Virtual Well&trade;</strong> technology to predict production for wells with short history.</v-card-text>
            <v-card-text><v-btn color="primary" x-large href="https://sky.alphaxds.com" target="_blank">Visit AlphaX Sky website!</v-btn></v-card-text>
          </template>
          <template v-if="current == 'log'">
            <blockquote class="blockquote text-right"><v-icon x-large>mdi-format-quote-open</v-icon><strong>Optimize log acquisition using the power of AI/ML to determine which logs add most information value to your existing database of log measurements</strong> - Ram Shenoy, 2019</blockquote>
            <v-card-title class="text-h2 mb-4">Log Analysis</v-card-title>
            <v-card-subtitle class="text-subtitle-1 font-weight-bold">AI-based analysis of well logs for vendor quality control and completions workflow simulations</v-card-subtitle>
            <v-card-text class="text-subtitle-1">Accelerate decision making through AX-AI based logs of shear, compressional, and density for vendor QC, well and completions workflow simulations, replacing corrupt logs, etc. The results are delivered in sub-seconds.</v-card-text>
            <v-card-actions class="blue--text pa-4">Available as a software license or for purchase.</v-card-actions>
          </template>
          <template v-if="current == 'facies'">
            <blockquote class="blockquote text-right"><v-icon x-large>mdi-format-quote-open</v-icon><strong>Subsurface interpretation using AI is Here</strong> - GEO ExPro, 2020</blockquote>
            <v-card-title class="text-h2 mb-4">Facies Prediction</v-card-title>
            <v-card-subtitle class="text-subtitle-1 font-weight-bold">Identify unique rock types from wireline logs for improved drill planning and completions design</v-card-subtitle>
            <v-card-text class="text-subtitle-1">Enable engineers to make faster, more informed decisions by integrating our facies detection models which describe tops (up to nine unique rock types) using wireline logs. Understanding the lithology enables more accurate drilling and completions design, and preempts downtime, and NPT concerns.</v-card-text>
            <v-card-actions class="blue--text pa-4">Available as a software license or for purchase.</v-card-actions>
          </template>
          <template v-if="current == 'seismic'">
            <blockquote class="blockquote text-right"><v-icon x-large>mdi-format-quote-open</v-icon><strong>Good decisions don't have to be slow ones</strong> - <a href="https://www.mckinsey.com/business-functions/organization/our-insights/good-decisions-dont-have-to-be-slow-ones">McKinsey, 2019</a></blockquote>
            <v-card-title class="text-h2 mb-4">Seismic Detection</v-card-title>
            <v-card-subtitle class="text-subtitle-1 font-weight-bold">Accelerate your workflow and reduce decision time for first picking, fault, and salt dome detections</v-card-subtitle>
            <v-card-text class="text-subtitle-1">Our deep learning models for first picking, fault, and salt dome detection provide high accuracy results up to 80 times faster than a human SME. The models have further shown accurate predictions of faults which SMEs had missed. Our AX-AI Seismic models have been developed on field data and continue to learn with new data.</v-card-text>
            <v-card-actions class="blue--text pa-4">Available as a software license or for purchase.</v-card-actions>
          </template>
          <template v-if="current == 'esp'">
            <blockquote class="blockquote text-right"><v-icon x-large>mdi-format-quote-open</v-icon><strong>Long and continuous runtimes of capital-intensive, highly-integrated assets can represent a significant competitive advantage.</strong> - <a href="https://www2.deloitte.com/content/dam/Deloitte/de/Documents/deloitte-analytics/Deloitte_Predictive-Maintenance_PositionPaper.pdf">Deloitte</a></blockquote>
            <v-card-title class="text-h2 mb-4">ESP Shutdown &amp; Failure</v-card-title>
            <v-card-subtitle class="text-subtitle-1 font-weight-bold">Predict equipment shutdown and failure events 20-30 minutes ahead of time to optimize production</v-card-subtitle>
            <v-card-text class="text-subtitle-1">AX-AI software tells you within an actionable period whether a shutdown or failure will occur, enabling continuous production optimization, unlike our competitors whose models predict failures days, weeks or months in advance, leaving engineers to guess at the ideal operational characteristics, maximizing production, planning, etc. This technology has been proven on electrical submersible pumps (ESP) and is extensible to rod pumps, artificial lift (AL), and surface equipment.</v-card-text>
            <v-card-actions class="blue--text pa-4">Available as a software license or for purchase.</v-card-actions>
          </template>
          <template v-if="current == 'drilling'">
            <blockquote class="blockquote text-right"><v-icon x-large>mdi-format-quote-open</v-icon><strong>Implementing automated solutions for drilling is critical to getting to a sustainable SG&amp;A</strong> - <a href="https://jpt.spe.org/take-10-oil-gas-energy-and-chemicals-2020">JPT, 2020</a></blockquote>
            <v-card-title class="text-h2 mb-4">Drilling Analytics</v-card-title>
            <v-card-subtitle class="text-subtitle-1 font-weight-bold">Determine bit location ahead of the survey and the bit position up to 100 feet ahead in real time</v-card-subtitle>
            <v-card-text class="text-subtitle-1">Drilling and completion costs constitute the largest component of project cost. As drilling will never be a one-size-fits-all operation, AX-AI software, which learns over time will be optimized to your data and can be integrated into your overall system enabling validation of drill plans and real time drilling workflows.</v-card-text>
          </template>
          <template v-if="current == 'anon'">
            <blockquote class="blockquote text-right"><v-icon x-large>mdi-format-quote-open</v-icon><strong>Industry Collaboration Increases Data Value</strong> - Hart Energy, 2018</blockquote>
            <v-card-title class="text-h2">Data Anonymization</v-card-title>
            <v-card-subtitle class="text-subtitle-1 font-weight-bold">Anonymize or de-identify your dataset for sharing with vendors, while preserving statistical signature</v-card-subtitle>
            <v-card-text class="text-subtitle-1">Data anonymization enables the transfer of information across a boundary, such as between two departments within an organization or between two organizations, while reducing the risk of unintended disclosure, and in a manner that enables evaluation and analytics post-anonymization. It can also be used to create aggregated datasets that allow companies to share data with others to mutually grow their operational efficiency. Anonymization obscures it permanently or it can also be done in a recoverable way (de-identification).</v-card-text>
            <v-card-actions class="blue--text pa-4">Available as a software license or for purchase.</v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "Software",
  data() {
    return {
      current: 0,
      products: [
        { id: "sky", title: "Production" },
        { id: "log", title: "Logs" },
        { id: "facies", title: "Facies" },
        { id: "seismic", title: "Seismic" },
        { id: "esp", title: "ESP Shutdown & Failure" },
        { id: "drilling", title: "Drilling" },
        { id: "anon", title: "Data Anonymization" }
      ],
    };
  }
};
</script>
